<template>
  <div class="person">
    <div class="personInfo">
      <img src="../assets/personBg.png" alt="" class="personInfo-bg" />
      <img
        src="../assets/shezhi.png"
        alt=""
        @click="goInstall()"
        class="shezhi"
      />
      <div class="info">
        <div class="infoHead" @click="goModify()">
          <img v-if="!userInfo.head_image" src="../assets/head.png" alt="" />
          <img v-if="userInfo.head_image" :src="userInfo.head_image" alt="" />
        </div>
        <div class="infoName">
          <div class="nameLeft">
            {{ userInfo.nick_name }}
          </div>
          <div class="nameRight">
            {{ userInfo.is_auth === 0 ? "未认证" : "已认证" }}
          </div>
        </div>
        <div class="areaAddress">
          <div>
            <p>区块链地址：{{ userInfo.wallet_address }}</p>
            <button
              class="shareBut1"
              :data-clipboard-text="copyText1"
              @click="copy1(userInfo.wallet_address)"
            >
              <img src="../assets/copy1.png" alt="" />
            </button>
          </div>
        </div>
        <div class="personProperty">
          <div class="propertyItem" @click="goBalance()">
            <h1>{{ userAccData.account }}</h1>
            <p>我的钱包</p>
          </div>
          <div class="line"></div>
          <div class="propertyItem">
            <h1>{{ userAccData.integral }}</h1>
            <p>我的积分</p>
          </div>
        </div>
      </div>
    </div>
    <div class="personCol">
      <nut-tabs @click="changeTabIndex" v-model="tab1value">
        <nut-tabpane title="我拥有的">
          <div class="personCol-type">
            <div class="personCol-type-div">
              <div
                @click="changeColType(0)"
                :class="colIndex == 0 ? 'active' : ''"
              >
                藏品
              </div>
              <div
                @click="changeColType(1)"
                :class="colIndex == 1 ? 'active' : ''"
              >
                盲盒
              </div>
            </div>
          </div>
          <div class="personCol-list" v-if="colIndex == 0">
            <div class="collectNull" v-if="userColData == ''">暂无数据</div>

            <div
              class="personCol-item"
              v-for="(item, index) in userColData"
              :key="item.id"
            >
              <div class="itemTop" @click="goDetail(item)">
                <img :src="item.image" alt="" />
              </div>
              <div class="itemBot">
                <div class="itemBot-div" @click="goDetail(item)">
                  <div class="itemBot-name">
                    {{ item.name }}
                  </div>
                  <div class="itemBot-price">
                    <span>￥</span>{{ item.price }}
                  </div>
                </div>
                <div class="itemBot-author">
                  <div class="authorLeft">艺术家</div>
                  <div class="authorRight">
                    <img :src="item.author_image" alt="" />
                    <span>{{ item.author_name }}</span>
                  </div>
                </div>
                <div class="itemBot-status">
                  <div class="itemBot-div1" v-if="item.status == 1">持有中</div>
                  <div class="itemBot-div1" v-if="item.status == 2">挂售中</div>
                  <div class="itemBot-div1" v-if="item.status == 3">交易中</div>
                  <div class="itemBot-div1" v-if="item.status == 4">已出售</div>
                  <div class="itemBot-div1" v-if="item.status == 5">已转赠</div>
                  <div
                    class="itemBot-but"
                    v-if="item.status == 1"
                    @click="zzOther(item)"
                  >
                    转增
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="personBox-list" v-if="colIndex == 1">
            <div class="collectNull" v-if="userBoxData == ''">暂无数据</div>

            <div
              class="personBox-item"
              v-for="(item, index) in userBoxData"
              :key="item.id"
            >
              <div class="itemTop">
                <img :src="item.image" alt="" />
              </div>
              <div class="itemBot">
                <div class="itemBot-div">
                  <div class="itemBot-name">
                    {{ item.name }}
                  </div>
                  <div class="itemBot-price">
                    <span>￥</span>{{ item.price }}
                  </div>
                </div>
                <div class="itemBot-status">
                  <div class="itemBot-div1" v-if="item.status == 1">持有中</div>
                  <div class="itemBot-div1" v-if="item.status == 2">挂售中</div>
                  <div class="itemBot-div1" v-if="item.status == 3">交易中</div>
                  <div class="itemBot-div1" v-if="item.status == 4">已出售</div>
                  <div class="itemBot-div1" v-if="item.status == 5">已打开</div>
                </div>
              </div>
            </div>
          </div>
        </nut-tabpane>
        <nut-tabpane title="邀请好友">
          <div class="personShare">
            <div class="shareTitle">我的邀请码</div>
            <h1>{{ shareData.uuid }}</h1>
            <img :src="shareData.qr_code_image" alt="" />
            <button
              @click="copy(shareData.register_url)"
              :data-clipboard-text="copyText"
              class="shareBut"
            >
              分享
            </button>
          </div>
        </nut-tabpane>
      </nut-tabs>
    </div>
    <!-- <nut-tabpane title="我卖出的">
          <div class="personCol-type">
            <div class="personCol-type-div">
              <div
                @click="changeColType1(0)"
                :class="colIndex1 == 0 ? 'active' : ''"
              >
                藏品
              </div>
              <div
                @click="changeColType1(1)"
                :class="colIndex1 == 1 ? 'active' : ''"
              >
                盲盒
              </div>
            </div>
          </div>
          <div class="personCol-list" v-if="colIndex == 0">
            <div class="collectNull" v-if="userColData == ''">暂无数据</div>

            <div
              class="personCol-item"
              @click="goDetail(item)"
              v-for="(item, index) in userColData"
              :key="item.id"
            >
              <div class="itemTop">
                <img :src="item.image" alt="" />
              </div>
              <div class="itemBot">
                <div class="itemBot-div">
                  <div class="itemBot-name">
                    {{ item.name }}
                  </div>
                  <div class="itemBot-price">
                    <span>￥</span>{{ item.price }}
                  </div>
                </div>
                <div class="itemBot-author">
                  <div class="authorLeft">艺术家</div>
                  <div class="authorRight">
                    <img :src="item.author_image" alt="" />
                    <span>{{ item.author_name }}</span>
                  </div>
                </div>
                <div class="itemBot-status">
                  <div class="itemBot-div1" v-if="item.status == 1">持有中</div>
                  <div class="itemBot-div1" v-if="item.status == 2">挂售中</div>
                  <div class="itemBot-div1" v-if="item.status == 3">交易中</div>
                  <div class="itemBot-div1" v-if="item.status == 4">已出售</div>
                  <div class="itemBot-div1" v-if="item.status == 5">已转赠</div>
                  <div class="itemBot-but">转增</div>
                </div>
              </div>
            </div>
          </div>
          <div class="personBox-list" v-if="colIndex == 1">
            <div class="collectNull" v-if="userBoxData == ''">暂无数据</div>

            <div
              class="personBox-item"
              v-for="(item, index) in userBoxData"
              :key="item.id"
            >
              <div class="itemTop">
                <img :src="item.image" alt="" />
              </div>
              <div class="itemBot">
                <div class="itemBot-div">
                  <div class="itemBot-name">
                    {{ item.name }}
                  </div>
                  <div class="itemBot-price">
                    <span>￥</span>{{ item.price }}
                  </div>
                </div>
                <div class="itemBot-status">
                  <div class="itemBot-div1" v-if="item.status == 1">持有中</div>
                  <div class="itemBot-div1" v-if="item.status == 2">挂售中</div>
                  <div class="itemBot-div1" v-if="item.status == 3">交易中</div>
                  <div class="itemBot-div1" v-if="item.status == 4">已出售</div>
                  <div class="itemBot-div1" v-if="item.status == 5">已打开</div>
                </div>
              </div>
            </div>
          </div>
        </nut-tabpane> -->
    <div class="zzTag" v-if="zzTagTF">
      <div class="zzTag-zzc" @click="closezzTag()"></div>
      <div class="zzTag-dialog">
        <h1>转增</h1>
        <input
          type="text"
          placeholder="请输入对方手机号"
          v-model="phoneValue"
        />
        <div class="line"></div>
        <div class="sureBtn" @click="zzBtn()">确认</div>
      </div>
    </div>
    <navBar></navBar>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import navBar from "../components/nav.vue";
import { Toast, Dialog } from "@nutui/nutui";
import {
  getUserInfo,
  getUserAcc,
  getUserCol,
  getUserBox,
  getShare,
  postTransfer,
} from "../api/person.js";
export default {
  components: { navBar },
  data() {
    return {
      zzTagTF: false,
      userInfo: {
        head_image: "",
        nick_name: "",
        wallet_address: "",
      },
      userAccData: {},
      tab1value: "0",
      colIndex: 0,
      colIndex1: 0,
      userColData: [],
      userBoxData: [],
      apiStatus: 1,
      shareData: {},
      copyText: "",
      itemDetail: "",
      phoneValue: "",
      copyText1: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/collectDetail") {
      to.meta.fromDetail = true;
    } else {
      to.meta.fromDetail = false;
    }
    next((e) => {});
  },
  activated() {
    console.log(this.$route.meta.fromDetail);
    if (!this.$route.meta.fromDetail) {
      if (!localStorage.getItem("app_token")) {
        this.goLogin();
      } else {
        this.getUserInfoData();
      }
    }
  },
  setup() {
    const onOk = () => {
      console.log("event ok");
    };
    return {
      onOk,
    };
  },
  mounted() {
    // if (!localStorage.getItem("app_token")) {
    //   this.goLogin();
    // } else {
    //   this.getUserInfoData();
    //   this.getUserAccData();
    //   this.getUserColData();
    // }
  },
  methods: {
    closezzTag() {
      this.zzTagTF = !this.zzTagTF;
    },
    zzBtn() {
      if (/^1[3-9][0-9]{9}$/.test(this.phoneValue)) {
        let params = {
          id: this.itemDetail.id,
          phone: this.phoneValue,
        };
        postTransfer(params).then((res) => {
          Toast.text(res.msg);
          this.closezzTag();
          this.getUserColData();
        });
      } else {
        Toast.text("请输入正确手机号");
      }
    },
    zzOther(item) {
      this.itemDetail = item;
      this.zzTagTF = !this.zzTagTF;
    },
    goBalance() {
      // 第一版不做提现
      // this.$router.push('/balance')
    },
    goInstall() {
      this.$router.push("/install");
    },
    goModify() {
      this.$router.push("/modifyInfo");
    },
    copy(item) {
      this.copyText = item;
      var clipboard = new Clipboard(".shareBut");
      clipboard.on("success", (e) => {
        Toast.text("链接复制成功快去分享吧");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        Toast.fail("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    copy1(item) {
      this.copyText1 = item;
      var clipboard = new Clipboard(".shareBut1");
      clipboard.on("success", (e) => {
        Toast.text("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        Toast.fail("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    changeTabIndex(title) {
      if (title.paneKey == 0) {
        this.apiStatus = 1;
        this.colIndex = 0;
        this.colIndex1 = 0;
        this.userColData = [];
        this.getUserColData();
      } else {
        this.getShareData();
      }
    },
    getShareData() {
      getShare().then((res) => {
        this.shareData = res.data;
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/collectDetail",
        query: {
          id: item.id,
        },
      });
    },
    getUserBoxData() {
      let params = {
        status: this.apiStatus,
      };
      getUserBox(params).then((res) => {
        this.userBoxData = res.data;
        console.log(this.userBoxData);
      });
    },
    getUserColData() {
      let params = {
        status: this.apiStatus,
      };
      getUserCol(params).then((res) => {
        this.userColData = res.data;
      });
    },
    changeColType(index) {
      this.colIndex = index;
      if (index === 1) {
        this.userBoxData = [];
        this.getUserBoxData();
      } else {
        this.userColData = [];
        this.getUserColData();
      }
    },
    changeColType1(index) {
      this.colIndex1 = index;
      if (index === 1) {
        this.userBoxData = [];
        this.getUserBoxData();
      } else {
        this.userColData = [];
        this.getUserColData();
      }
    },
    getUserAccData() {
      let params = {};
      getUserAcc(params).then((res) => {
        this.userAccData = res.data;
        this.getUserColData();
      });
    },
    getUserInfoData() {
      let params = {};
      getUserInfo(params).then((res) => {
        if (res.code == 401) {
          localStorage.removeItem("app_token");
          this.goLogin();
        } else {
          this.userInfo = res.data;
          this.getUserAccData();
        }
      });
    },
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss">
.person {
  .personInfo {
    width: 100%;
    position: relative;
    .personInfo-bg {
      width: 100%;
    }
    .shezhi {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 28px;
    }
    .info {
      position: absolute;
      top: 37px;
      width: 100%;
      left: 0;

      .infoHead {
        overflow: hidden;
        background-color: #fff;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #fff;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .infoName {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .nameLeft {
          font-size: 17px;
          color: #ffffff;
        }
        .nameRight {
          padding: 2px 5px;
          font-size: 9px;
          color: #ffffff;
          background-color: rgba($color: #fff, $alpha: 0.5);
          border-radius: 10px;
          margin-left: 4px;
        }
      }
      .areaAddress {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
        div {
          width: 226px;
          background-color: rgba($color: #fff, $alpha: 0.3);
          padding: 4px 12px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          p {
            font-size: 11px;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
          }
          button {
            display: block;
            border: 0;
            outline: none;
            width: 11px;
            margin-left: 4px;
            background-color: unset;
            img {
              width: 100%;
            }
          }
        }
      }
      .personProperty {
        width: 325px;
        margin: 0 auto;
        margin-top: 25px;
        height: 70px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(230, 229, 229, 0.5);
        border-radius: 5px;
        display: flex;
        align-items: center;
        .line {
          width: 1px;
          height: 47px;
          background-color: #ededed;
        }
        .propertyItem {
          flex: 1;
          text-align: center;
          h1 {
            font-size: 18px;
            color: #333333;
          }
          p {
            font-size: 13px;
            color: #666666;
          }
        }
      }
    }
  }
  .personCol {
    padding-top: 58px;
    padding-bottom: 65px;
    .collectNull {
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
    }
    .nut-tabpane {
      padding: 0;
      padding-top: 11px;
      background-color: unset;
    }
    .nut-tabs__titles-item.active .nut-tabs__titles-item__line {
      width: 10px;
      height: 3px;
      background: linear-gradient(180deg, #626262 0%, #333333 100%);
      border-radius: 3px;
    }
    .nut-tabs__titles-item {
      color: #999999;
    }
    .nut-tabs__titles-item.active {
      color: #333333;
    }
    .personCol-type {
      display: flex;
      justify-content: center;
      .personCol-type-div {
        width: 114px;
        height: 30px;
        background: #e6e7ea;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          font-size: 13px;
          color: #888888;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
        }
        div.active {
          width: 60px;
          height: 30px;
          background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
          border-radius: 10px;
          color: #333333;
        }
      }
    }
    .personCol-list {
      padding: 0 15px;
      margin-top: 10px;
      .personCol-item {
        margin-bottom: 15px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 7px 0px #eef1fd;
        border-radius: 10px;
        overflow: hidden;
        .itemTop {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .itemBot {
          padding: 15px;
          .itemBot-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .itemBot-name {
              font-size: 17px;
              color: #333333;
              font-weight: bold;
            }
            .itemBot-price {
              font-size: 22px;
              color: #333333;
              font-weight: bold;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
          .itemBot-author {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            .authorLeft {
              font-size: 14px;
              color: #333333;
            }
            .authorRight {
              display: flex;
              align-items: center;

              img {
                width: 18px;
                margin-right: 5px;
              }
              span {
                font-size: 12px;
                color: #999999;
              }
            }
          }
          .itemBot-status {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 7px;
            .itemBot-div1 {
              font-size: 14px;
              background: linear-gradient(42deg, #52bee0 0%, #91affc 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .itemBot-but {
              width: 78px;
              height: 25px;
              border-radius: 15px;
              border: 1px solid #8ddaf3;
              font-size: 13px;
              background: linear-gradient(30deg, #4bc1e7 0%, #9db7fb 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              line-height: 25px;
            }
          }
        }
      }
    }
    .personBox-list {
      padding: 0 15px;
      margin-top: 10px;
      .personBox-item {
        margin-bottom: 15px;
        overflow: hidden;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 7px 0px #eef1fd;
        border-radius: 10px;
        .itemTop {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .itemBot {
          padding: 15px;
          .itemBot-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .itemBot-name {
              font-size: 17px;
              color: #333333;
              font-weight: bold;
            }
            .itemBot-price {
              font-size: 22px;
              color: #333333;
              font-weight: bold;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
          .itemBot-status {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 7px;
            .itemBot-div1 {
              font-size: 14px;
              background: linear-gradient(42deg, #52bee0 0%, #91affc 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .itemBot-but {
              width: 78px;
              height: 25px;
              border-radius: 15px;
              border: 1px solid #8ddaf3;
              font-size: 13px;
              background: linear-gradient(30deg, #4bc1e7 0%, #9db7fb 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              line-height: 25px;
            }
          }
        }
      }
    }
    .personShare {
      .shareTitle {
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
      h1 {
        text-align: center;
        font-size: 30px;
        margin: 4px 0 10px 0;
      }
      img {
        width: 150px;
        margin: 0 auto;
      }
      .shareBut {
        background: linear-gradient(314deg, #9598f8, #5c5fef);
        border-radius: 15px;
        color: #fff;
        width: 90px;
        height: 30px;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        margin: 15px auto;
        border: 0;
        outline: none;
        display: block;
      }
    }
  }
  .zzTag {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    .zzTag-zzc {
      background-color: rgba($color: #000, $alpha: 0.7);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .zzTag-dialog {
      width: 264px;
      background-color: #fff;
      border-radius: 10px;
      padding: 15px;
      position: relative;
      z-index: 1;
      h1 {
        text-align: center;
        font-size: 16px;
      }
      input {
        font-size: 14px;
        height: 50px;
        text-indent: 20px;
      }
      .line {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        height: 1px;
      }
      .sureBtn {
        font-size: 16px;
        color: #fff;
        background-color: #007aff;
        border-radius: 5px;
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        margin-top: 20px;
      }
    }
  }
}
</style>